import * as React from "react";
import { graphql, Link } from "gatsby";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import { useMutation, useQuery } from "react-apollo";
import Meta from "../../../components/meta";
import gql from "graphql-tag";
import Layout from "../../../components/layout/Layout";
import Content from "../../../components/layout/Content/Content";
import Headline from "../../../components/texts/Headline";
import Description from "../../../components/texts/Description";
import Button from "../../../components/buttons/Button";
import BackgroundMerlin from "../../../components/pages/merlin/BackgroundMerlin";

import constants from "../../../constants";
import Storage from "../../../storage";

const MERLIN_INVITE = gql`
  query GetMerlinInvite($inviteSlug: String!) {
    merlin {
      invite(inviteSlug: $inviteSlug) {
        numberOfInvitesAllowed
        numberOfInvitesAvailable
      }
    }
  }
`;

const Container = Styled.div`
  padding: 6rem 0 3rem 0;
  overflow: hidden; // fix for columns overflow

  ${Media.greaterThan("medium")`
    padding: 15rem 0 5rem 0
  `}
`;

const ContentExtended = Styled(Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 107rem;
  max-width: calc(100% - 4rem);
`;

const DescriptionExtended = Styled(Description)`
  width: 100%;
  margin: 0 auto 3rem auto;
  font-size: 2.6rem;
  text-align: center;
  font-weight: 500;

  ${Media.greaterThan("700px")`
    width: 80%;
    font-size: 3rem;
    margin-bottom: 3rem;
    letter-spacing: -1px;
  `}
`;

const ExtendedHeadline = Styled(Headline)`
  text-align: center;
  font-size: 3.2rem;
  line-height: 3.2rem;
  max-width: 100%;
  margin: 0 0 4rem 0;
  font-weight: 500;
  letter-spacing: -2px;
  
  ${Media.greaterThan("medium")`
    font-size: 6rem;
    line-height: 6rem;
    letter-spacing: -3px;
    margin: 0 auto 4rem auto;
  `}
`;


const ButtonExtended = Styled(Button)`
  border: none;
  color: #FFFFFF;
  background: linear-gradient(to right, #0094FF, #7000FF);
  border-radius: 16px;
  height: 6.2rem;
  margin: 0 auto;

  &:active {
    background: radial-gradient(#7000FF, #0094FF);
  }
`;

const Buttons = Styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;


const getSlug = () => {
  try {
    const url = new URL(window.location.href);
    const params = url.searchParams;
    const slug = params.get("slug");
    return slug;
  } catch (error) {
    console.error("Failed to retrieve slug.");
  }
};

const MerlinInvitePage = () => {
  const inviteSlug = getSlug();

  const { data, loading, error } = useQuery(MERLIN_INVITE, {
    variables: {
      inviteSlug
    },
  });

  Storage.setItem('merlin-invite-slug', `${inviteSlug}`, { crossDomain: true });
  
  const { numberOfInvitesAllowed, numberOfInvitesAvailable } = React.useMemo(() => (
    data?.merlin.invite ?? { numberOfInvitesAllowed: 0, numberOfInvitesAvailable: 0 }
  ), [data]);

  const hasInvitesLeft = numberOfInvitesAvailable > 0;

  console.log({ hasInvitesLeft })

  if (loading) {
    return (
      <ContentExtended>
        <DescriptionExtended>
          Loading...
        </DescriptionExtended>
      </ContentExtended>
    )
  }

  if (error || !inviteSlug) {
    return (
      <React.Fragment>
        <ExtendedHeadline as="h1">Whoops! 😱</ExtendedHeadline>
        <DescriptionExtended>
          It seems that the link you have followed in invalid.
        </DescriptionExtended>
      </React.Fragment>
    )
  }

  if (hasInvitesLeft) {
    return (
      <React.Fragment>
        <ExtendedHeadline as="h1">You are invited to try Autodesigner! 🎉</ExtendedHeadline>

        <DescriptionExtended>
          This invitation link still have {numberOfInvitesAvailable} out of {numberOfInvitesAllowed} invites left!
          <br />
          <br />
          Hurry up and get immediate access to Autodesigner by signing up or logging in to Uizard if you're already a user!
          <br />
          <br />
        </DescriptionExtended>

          <Buttons>
            <a href={`https://${constants.APP_DOMAIN}/sign-up`}>
              <Button solid>Sign up</Button>
            </a>

            <a href={`https://${constants.APP_DOMAIN}/login`}>
              <Button solid>Log in</Button>
            </a>
          </Buttons>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <ExtendedHeadline as="h1">Bummer! 😢</ExtendedHeadline>

      <DescriptionExtended>
        The invitation link you followed has unfortunately already been used by {numberOfInvitesAllowed} users to get immediate access to Autodesigner and is therefore
        no longer valid.
        <br />
        <br />
        But don't worry! You can still sign up for the waiting list and get notified once you can get access to Autodesigner.
      </DescriptionExtended>

      <Link to="/autodesigner">
        <ButtonExtended>
          Sign up to waiting list
        </ButtonExtended>
      </Link>
    </React.Fragment>
  );
};

const MerlinInviteWrapper = ({ data }) => (
  <Layout type="dark" news="hide">
    <Meta
      title="Uizard Autodesigner | You Are Invited To Autodesigner | Uizard"
      description="You visited an invitation link for Autodesigner."
      url="/autodesigner/invite"
      metaimage="https://uizard.io/opengraph-autodesigner-2023-02-17.png"
      noindex={true}
    />
    
    <BackgroundMerlin
      backgroundGridWeb={data.backgroundGridWeb.childImageSharp}
      backgroundGridMobile={data.backgroundGridMobile.childImageSharp}
      alpha={0.5}
    />

    <Container>
      <ContentExtended>
        <MerlinInvitePage />
      </ContentExtended>
    </Container>

  </Layout>
);

const MerlinInvitePageRelease = () => {
  const inviteSlug = getSlug();
  Storage.setItem('merlin-invite-slug', `${inviteSlug}`, { crossDomain: true });

  React.useEffect(() => {
    window.location.href = '/autodesigner';
  }, []);

  return null;
}

export default MerlinInvitePageRelease;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    backgroundGridWeb: file(relativePath: { eq: "merlin/grid-web.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    backgroundGridMobile: file(relativePath: { eq: "merlin/grid-mobile.png" }) {
      childImageSharp {
        fluid(maxWidth: 375, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
